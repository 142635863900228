import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';


const Index = () => (
  <>
    <SEO title="The Battle of Warsaw 1920" lang="en" description="History of the most important battle you probably haven’t heard of." />
    <Intro locale="en" />
  </>
);
export default Index;
